import React, { useState, useEffect, useContext } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { fetchMembersData } from "./members_api_data";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import AccessLevelContext from "../../components/AccessLevelContext";

const Subscriptions = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [membersData, setMembersData] = useState([]);
  const accessLevel = useContext(AccessLevelContext);

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "membership_id",
      headerName: "Membership #",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "age",
      headerName: "Age",
      flex: 1,
    },
    {
      field: "membership",
      headerName: "Membership",
      flex: 1,
    },
    {
      field: "subscription",
      headerName: "Subscription",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "payment_period",
      headerName: "Payment Date",
      flex: 1,
    },
    {
      field: "payment_type",
      headerName: "Payment Method",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchMembersData(false);
      setMembersData(data);
    };
    fetchData();
  }, []);


  const handleRowClick = (params) => {
    const { membership_id } = params.row;
    navigate(`/member/${membership_id}`)
  };

  return (
    <Box m="10px">
      <Header title="Subscriptions" subtitle="Manage Subscriptions" />
      <Box
        m="20px 0 0 0"
        height="65vh"
        width='100%'
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={membersData}
          columns={columns}
          getRowId={(row) => row.membership_id}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default Subscriptions;
