import React from "react";
import { Box, Button, useTheme } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import Header from "../../components/Header";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import { useForm, Controller } from "react-hook-form";

function Login({ onLogin, error }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { control, handleSubmit } = useForm();

    const CssTextField = styled(TextField)({
        '&.MuiTextField-root': {
            margin: "5px"
        },
        '& label.Mui-focused': {
            color: colors.greenAccent[300],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.blueAccent[200],
            },
            '&:hover fieldset': {
                borderColor: colors.greenAccent[500],
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.blueAccent[500],
            },
        },
    });

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
        >
            <Box minWidth="25%">
                <Box
                    display="flex"
                    justifyContent="center"
                >
                    <Header title="LOGIN" subtitle="" />
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                >
                    {error ? (
                        <p style={{ color: colors.redAccent[500] }}>{error}</p>
                    ) : (<p></p>)}
                </Box>

                <form onSubmit={handleSubmit((data) => onLogin(data.username, data.password))}>
                    <Grid spacing={2} margin={2}>
                        <Controller
                            name="username"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    required
                                    label="Username"
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid spacing={2} margin={2}>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    required
                                    label="Password"
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <Button type="submit" variant="contained" margin={2} fullWidth sx={{ bgcolor: colors.blueAccent[500], fontWeight: 900 }}>Login</Button>
                    </Box>

                </form>
            </Box>

        </Box>
    );
}

export default Login;
