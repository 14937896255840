import React, { useState, useEffect } from "react";
import { Box, IconButton, useTheme, Typography } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [searchResults, setSearchResults] = useState([]);
  const [query, setQuery] = useState();
  const navigate = useNavigate();

  const search = async (e) => {
    const query = e.target.value;
    setQuery(query)
    if (query) {
      const token = sessionStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}members/search?q=${query}`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
      );
      const data = await response.json();
      setSearchResults(data);
    } else {
      setSearchResults([])
    }

  }

  const handleRowClick = (membership_id) => {
    navigate(`/member/${membership_id}`)
    setSearchResults([])
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
        width="80%"
      >
        <Box width="100%" display="flex" flexDirection="column">
          <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search by Membership #, Name or Address" onChange={(e) => { search(e) }} />
          {searchResults ? (
            <Box sx={{ ml: 2, flex: 1}}>
              {searchResults.map((result) => (
                <Box 
                  display="flex"
                  flexDirection="column"
                  sx={{
                    cursor: "pointer",
                    p: 1,
                    '&:hover': {
                      backgroundColor: colors.primary[100],
                      color: colors.primary[400]
                    },
                  }}
                  onClick={() => {handleRowClick(result.membership_id)}}
                >
                  <Typography variant="h5">{result.membership_id} {result.first_name} {result.middle_name} {result.last_name}</Typography>
                  <Typography variant="p" color="gray">{result.address}</Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <div></div>
          )}
        </Box>
        {query ? (
          <CloseIcon sx={{ m: 1, color: "gray", cursor: "pointer", '&:hover': {color: "white"}}} onClick={() => {setSearchResults([])}}/>
        ):(
          <SearchIcon sx={{ m: 1, color: "gray" }} />
        )}
      </Box>


      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton>
          <PersonOutlinedIcon />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default Topbar;
