import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Members from "./scenes/members";
import NewMember from "./scenes/newMember";
import Users from "./scenes/users";
import Member from "./scenes/memberPage";
import Login from "./scenes/login";
import Logout from "./scenes/logout";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import ResetPassword from "./scenes/reset_password";
import InactiveMembers from "./scenes/inactive_members";
import NewUser from "./scenes/newUsers";
import AccessLevelContext from "./components/AccessLevelContext";
import Subscriptions from "./scenes/subscriptions";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [accessLevel, setAccessLevel] = useState();

  const handleLogin = async (username, password) => {
    try {
      let formdata = new FormData();

      formdata.append("username", username);
      formdata.append("password", password);
      const response = await fetch(`${process.env.REACT_APP_API_URL}login`, {
        method: "POST",
        body: formdata,
        redirect: "follow",
      });

      if (response.ok) {
        const data = await response.json();
        sessionStorage.setItem("token", data.access_token);
        setAccessLevel(data.access_level);
        navigate("/");
      } else {
        // Handle login error
        setError("Invalid Username/Password");
      }
    } catch (error) {
      setError("Invalid Username/Password");
      console.error("An error occurred during login", error);
    }
  };

  const isAuthenticated = () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return false;
    }

    try {
      const decoded = jwt_decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  };

  if (!isAuthenticated()) {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <main className="content">
              <Routes>
                <Route
                  path="/login"
                  element={<Login onLogin={handleLogin} error={error} />}
                />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  } else {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <AccessLevelContext.Provider value={accessLevel}>
            <CssBaseline />
            <div className="app">
              <Sidebar isSidebar={isSidebar}/>
              <main className="content">
                <Topbar setIsSidebar={setIsSidebar} />
                <Routes>
                  <Route path="/" element={<Members />} />
                  <Route
                    path="/members"
                    element={<Members />}
                  />
                  <Route
                    path="/members/inactive"
                    element={<InactiveMembers />}
                  />
                  {accessLevel === "ADMIN" && (
                    <Route
                      path="/users"
                      element={<Users />}
                    />
                  )}
                  <Route
                    path="/user/new"
                    element={<NewUser />}
                  />
                  <Route
                    path="/member/new"
                    element={<NewMember/>}
                  />
                  <Route
                    path="/member/:id"
                    element={<Member />}
                  />
                  <Route
                    path="/members/subscriptions"
                    element={<Subscriptions />}
                  />
                  <Route path="/logout" element={<Logout />} />
                </Routes>
              </main>
            </div>
          </AccessLevelContext.Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  }
}

export default App;
