import React, { useEffect, useState } from 'react'
import { Typography } from "@mui/material";
import { fetchMemberById } from './member_by_id_data';
import { useParams } from "react-router-dom";
import { TextField, Select, MenuItem, Grid, FormControl, InputLabel } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { updateMember } from './update_member';
import PushNotification from '../../components/PushNotification';


const MemberPage = ({ accessLevel }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [member, setMember] = useState();
    const params = useParams();
    const [maritalStatus, setMaritalStatus] = useState();
    const [membershipId, setMembershipId] = useState();
    const [numChildren, setNumChildren] = useState(0);
    const [showNotif, setShowNotif] = useState(false);
    const { control, handleSubmit } = useForm();

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchMemberById(params.id);
            console.log(data)
            setMember(data);
            setMaritalStatus(data.marital_status)
            setNumChildren(data.number_of_children)
            setMembershipId(data.membership_id)
        };
        fetchData();
    }, [params.id]);

    const CssTextField = styled(TextField)({
        '&.MuiTextField-root': {
            margin: "5px"
        },
        '& label.Mui-focused': {
            color: colors.greenAccent[300],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.blueAccent[200],
            },
            '&:hover fieldset': {
                borderColor: colors.greenAccent[500],
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.blueAccent[500],
            },
        },
    });

    const handleMaritalStatusChange = (event) => {
        setMaritalStatus(event.target.value);
    };

    const onSubmit = async (data) => {
        const memberData = await updateMember(membershipId, data)
        if (memberData) {
            setShowNotif(true)
            setTimeout(() => {
                setShowNotif(false);
            }, 6000);
        }
    }

    const existingChildrenFields = []
    const childrenFields = [];
    if (member && member.children) {
        for (let i = 0; i < member.children.length; i++) {
            const childNum = i + 1;
            existingChildrenFields.push(
                <Grid container spacing={2} key={i} marginTop={2}>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name={`children[${i}].first_name`}
                            control={control}
                            defaultValue={member.children[i].first_name}
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    label={`Child ${childNum} First Name`}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name={`children[${i}].middle_name`}
                            control={control}
                            defaultValue={member.children[i].middle_name}
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    label={`Child ${childNum} Middle Name`}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name={`children[${i}].last_name`}
                            control={control}
                            defaultValue={member.children[i].last_name}
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    label={`Child ${childNum} Last Name`}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} marginBottom={2}>
                        <Controller
                            name={`children[${i}].date_of_birth`}
                            control={control}
                            defaultValue={member.children[i].date_of_birth}
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    llabel={`Child ${childNum} Date of Birth`}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name={`children[${i}].gender`}
                            control={control}
                            defaultValue={member.children[i].gender}
                            render={({ field }) => (
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="gender-select-label">Gender</InputLabel>
                                    <Select
                                        labelId="gender-select-label"
                                        id="gender-select"
                                        {...field}
                                        label={`Child ${childNum} Gender`}
                                        required
                                    >
                                        <MenuItem value="male">Male</MenuItem>
                                        <MenuItem value="female">Female</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Grid>
                </Grid>
            );
        }
    }
    if (member) {
        for (let i = 0; i < (numChildren - member.children.length); i++) {
            let childNum = i + 1
            if (member.children) {
                childNum = i + 1 + member.children.length
            }

            childrenFields.push(
                <Grid container spacing={2} key={i} marginTop={2}>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name={`children[${i + member.children.length}].first_name`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    label={`Child ${childNum} First Name`}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name={`children[${i + member.children.length}].middle_name`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    label={`Child ${childNum} Middle Name`}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name={`children[${i + member.children.length}].last_name`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    label={`Child ${childNum} Last Name`}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} marginBottom={2}>
                        <Controller
                            name={`children[${i + member.children.length}].date_of_birth`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    label={`Child ${childNum} Date of Birth`}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            );
        }
    }



    return (
        <div>
            {member ? (
                <div>

                    <Typography variant="h1" margin={4}>
                        {member.name}
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} margin={2}>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="membership_id"
                                    control={control}
                                    defaultValue={member.membership_id}
                                    render={({ field }) => (
                                        <CssTextField
                                            {...field}
                                            required
                                            label="Membership #"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="status"
                                    control={control}
                                    defaultValue={member.status}
                                    render={({ field }) => (
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="status-select-label">Status</InputLabel>
                                            <Select
                                                labelId="status-select-label"
                                                id="status-select"
                                                {...field}
                                                label="Status"
                                                required
                                            >
                                                <MenuItem value="active">Active</MenuItem>
                                                <MenuItem value="inactive">Inactive</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue={member.name}
                                    render={({ field }) => (
                                        <CssTextField
                                            {...field}
                                            required
                                            label="Name"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="gender"
                                        control={control}
                                        defaultValue={member.gender}
                                        render={({ field }) => (
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="gender-select-label">Gender</InputLabel>
                                                <Select
                                                    labelId="gender-select-label"
                                                    id="gender-select"
                                                    {...field}
                                                    label="Gender"
                                                    required
                                                >
                                                    <MenuItem value="male">Male</MenuItem>
                                                    <MenuItem value="female">Female</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="date_of_birth"
                                        control={control}
                                        defaultValue={member.date_of_birth}
                                        render={({ field }) => (
                                            <CssTextField
                                                {...field}
                                                label="DOB"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="address"
                                        control={control}
                                        defaultValue={member.address}
                                        render={({ field }) => (
                                            <CssTextField
                                                {...field}
                                                label="Address"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue={member.email}
                                        render={({ field }) => (
                                            <CssTextField
                                                {...field}
                                                label="Email"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="telephone"
                                        control={control}
                                        defaultValue={member.telephone}
                                        render={({ field }) => (
                                            <CssTextField
                                                {...field}
                                                label="Phone Number 1"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="telephone2"
                                        control={control}
                                        defaultValue={member.telephone2}
                                        render={({ field }) => (
                                            <CssTextField
                                                {...field}
                                                label="Phone Number 2"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="marital_status"
                                    control={control}
                                    defaultValue={maritalStatus}
                                    render={({ field }) => (
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="marital_status-select-label">Marital Status</InputLabel>
                                            <Select
                                                labelId="marital_status-select-label"
                                                id="marital_status-select"
                                                {...field}
                                                label="Marital Status"
                                                required
                                                value={maritalStatus}
                                                onChange={handleMaritalStatusChange}
                                            >
                                                <MenuItem value="single">Single</MenuItem>
                                                <MenuItem value="married">Married</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="description"
                                        control={control}
                                        defaultValue={member.description}
                                        render={({ field }) => (
                                            <CssTextField
                                                {...field}
                                                label="Description"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            {maritalStatus === "married" && (
                                <Box sx={{ mt: 2, ml: 2 }}>

                                    <Typography variant="h3" margin={4}>
                                        Partner
                                    </Typography>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="partner_first_name"
                                                control={control}
                                                defaultValue={member.partner_first_name}
                                                render={({ field }) => (
                                                    <CssTextField
                                                        {...field}
                                                        label="Partner's First Name"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="partner_middle_name"
                                                control={control}
                                                defaultValue={member.partner_middle_name}
                                                render={({ field }) => (
                                                    <CssTextField
                                                        {...field}
                                                        label="Partner's Middle Name"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="partner_last_name"
                                                control={control}
                                                defaultValue={member.partner_last_name}
                                                render={({ field }) => (
                                                    <CssTextField
                                                        {...field}
                                                        label="Partner's Last Name"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="partner_date_of_birth"
                                                control={control}
                                                defaultValue={member.partner_date_of_birth}
                                                render={({ field }) => (
                                                    <CssTextField
                                                        {...field}
                                                        label="DOB"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="partner_email"
                                                control={control}
                                                defaultValue={member.partner_email}
                                                render={({ field }) => (
                                                    <CssTextField
                                                        {...field}
                                                        label="Partner's Email"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="partner_telephone"
                                                control={control}
                                                defaultValue={member.partner_telephone}
                                                render={({ field }) => (
                                                    <CssTextField
                                                        {...field}
                                                        label="Partner's Phone"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="partner_gender"
                                                control={control}
                                                defaultValue={member.partner_gender}
                                                render={({ field }) => (
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="gender-select-label">Gender</InputLabel>
                                                        <Select
                                                            labelId="gender-select-label"
                                                            id="gender-select"
                                                            {...field}
                                                            label="Partner's Gender"
                                                            required
                                                        >
                                                            <MenuItem value="male">Male</MenuItem>
                                                            <MenuItem value="female">Female</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="numberKids"
                                            control={control}
                                            defaultValue={member.number_of_children}
                                            render={({ field }) => (
                                                <CssTextField
                                                    {...field}
                                                    label="No. Of Children"
                                                    type="number"
                                                    variant="outlined"
                                                    value={numChildren}
                                                    onChange={(event) => setNumChildren(event.target.value)}
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {numChildren > 0 && (
                                        <Typography variant="h3" margin={4}>
                                            Children
                                        </Typography>
                                    )}
                                    {existingChildrenFields}
                                    {childrenFields}
                                </Box>)}
                        </Grid>
                        {accessLevel === "ADMIN" && (
                            <Box display="flex" justifyContent="center" p="20px">
                                <Button type="submit" variant="contained" sx={{ bgcolor: colors.blueAccent[500], fontWeight: 900 }}>Update Member</Button>
                            </Box>
                        )}
                    </form>
                    {showNotif ? (
                        <PushNotification message="Member Updated" />)
                        : (<></>
                        )}
                </div>
            ) : (
                <Typography variant="h1" margin={4}>
                    Loading...
                </Typography>
            )}
        </div>
    )
}

export default MemberPage
