import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem("token");
  };

  useEffect(() => {
    handleLogout();
    navigate('/login');
  }, []);

  return null;
}

export default Logout
