import React, {useContext} from 'react'
import Typography from "@mui/material/Typography";
import NewMemberForm from './newMemberForm';
import AccessLevelContext from '../../components/AccessLevelContext';


const NewMember = () => {
  const accessLevel = useContext(AccessLevelContext);
  return (
    <div>
      <Typography variant="h1" margin={4}>
        Add New Member
      </Typography>
      {accessLevel === "ADMIN" && (
        <NewMemberForm />
      )}
    </div>

  )
}

export default NewMember
