export const fetchMembersData = async (show_inactive) => {
    const token = sessionStorage.getItem("token");
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}members?show_inactive=${show_inactive}`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  };
  