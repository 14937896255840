import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Select, MenuItem, Grid, FormControl, InputLabel } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import PushNotification from '../../components/PushNotification';
import AccessLevelContext from "../../components/AccessLevelContext";


const NewUserForm = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [showNotif, setShowNotif] = useState(false)
    const [error, setError] = useState()

    const CssTextField = styled(TextField)({
        '&.MuiTextField-root': {
            margin: "5px"
        },
        '& label.Mui-focused': {
            color: colors.greenAccent[300],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.blueAccent[200],
            },
            '&:hover fieldset': {
                borderColor: colors.greenAccent[500],
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.blueAccent[500],
            },
        },
    });

    const onSubmit = async (email, username, access_level) => {
        const userData = {
            email,
            username,
            access: access_level
        };

        const token = sessionStorage.getItem("token");
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}user`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(userData),
            });

            if (response.status === 409) {
                setError("User with same username or email already exisits")
            } else if (!response.ok){
                setError("Unable to create user")
            }

            const data = await response.json();
            if (response.ok) {
                setShowNotif(true)
                setTimeout(() => {
                    setShowNotif(false);
                }, 6000);
            }
            return data
        } catch (error) {
            console.error(error);
            setError('An error occurred while creating user.');
        }
    }

    const { control, handleSubmit } = useForm();

    return (
        <form onSubmit={handleSubmit((data) => onSubmit(data.email, data.username, data.access_level))}>
            <Box
                display="flex"
                justifyContent="center"
            >
                {error ? (
                    <p style={{ color: colors.redAccent[500] }}>{error}</p>
                ) : (<p></p>)}
            </Box>
            <Grid container spacing={2} margin={2}>
                <Grid item xs={12} sm={4}>
                    <Controller
                        name="username"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <CssTextField
                                {...field}
                                required
                                label="Username"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <CssTextField
                                required
                                {...field}
                                label="Email"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Controller
                        name="access_level"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="access_level-select-label">Access</InputLabel>
                                <Select
                                    labelId="access_level-select-label"
                                    id="access_level-select"
                                    {...field}
                                    label="Access Level"
                                    required
                                >
                                    <MenuItem value="admin">Admin</MenuItem>
                                    <MenuItem value="user">User</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>

            </Grid>
            <Box display="flex" justifyContent="center" p="20px">
                <Button type="submit" variant="contained" sx={{ bgcolor: colors.blueAccent[500], fontWeight: 900 }}>Add User</Button>
            </Box>
            {showNotif ? (
                <PushNotification message="User Added" />)
                : (<></>
                )}
        </form>
    );
}


function NewUser() {
    const accessLevel = useContext(AccessLevelContext);
    return (
        <div>
            <Typography variant="h1" margin={4}>
                Add New User
            </Typography>
            {accessLevel === "ADMIN" && (
                <NewUserForm />
            )}
        </div>
    )
}

export default NewUser
