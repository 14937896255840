import React, { useState, useEffect, useContext } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { fetchMembersData } from "./members_api_data";
import Header from "../../components/Header";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import AccessLevelContext from "../../components/AccessLevelContext";
import { Link } from "react-router-dom";

const Members = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [membersData, setMembersData] = useState([]);
  const accessLevel = useContext(AccessLevelContext);

  const columns = [
    { field: "id", headerName: "id" },
    { field: "membership_id", headerName: "Membership #" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "telephone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchMembersData(false);
      setMembersData(data);
    };
    fetchData();
  }, []);


  const handleRowClick = (params) => {
    const { membership_id } = params.row;
    navigate(`/member/${membership_id}`)
  };

  return (
    <Box m="10px">
      <Header title="Members" subtitle="Manage Members" />
      {/* <Box display="flex" justifyContent="space-between">
        <Box p="10px">
          <FormControlLabel
            label="Inactive Members"
            control={<Checkbox sx={{
              color: colors.grey[100],
              '&.Mui-checked': {
                color: colors.grey[100],
              },
            }} checked={checked[0]} onChange={handleChange} />}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" p="10px">
          <Button href="/member/new" variant="contained" sx={{ bgcolor: colors.blueAccent[500], fontWeight: 900, mr: "20px" }}>Add Member</Button>
        </Box>
      </Box> */}
      {accessLevel === "ADMIN" && (
        <Box display="flex" justifyContent="flex-end" p="10px">
          <Button
            component={Link}
            to="/member/new"
            variant="contained"
            sx={{ bgcolor: colors.blueAccent[500], fontWeight: 900, mr: "20px" }}>
              Add Member
          </Button>
        </Box>
      )}
      <Box
        m="20px 0 0 0"
        height="65vh"
        width='100%'
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={membersData}
          columns={columns}
          getRowId={(row) => row.id}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default Members;
