import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Select, MenuItem, Grid, FormControl, InputLabel } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import PushNotification from '../../components/PushNotification';


export default function MyForm() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [maritalStatus, setMaritalStatus] = useState("");
  const [numChildren, setNumChildren] = useState(0);
  const [showNotif, setShowNotif] = useState(false)

  const CssTextField = styled(TextField)({
    '&.MuiTextField-root': {
      margin: "5px"
    },
    '& label.Mui-focused': {
      color: colors.greenAccent[300],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.blueAccent[200],
      },
      '&:hover fieldset': {
        borderColor: colors.greenAccent[500],
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.blueAccent[500],
      },
    },
  });

  const deleteEmptyStrings = (obj) => {
    for (let key in obj) {
      if (typeof obj[key] === 'string' && obj[key].trim() === '') {
        delete obj[key];
      }
    }
  }

  const onSubmit = async (data) => {
    const url = `${process.env.REACT_APP_API_URL}members/add`
    deleteEmptyStrings(data)
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log(data.message); // should print "success"
      if (response.ok) {
        setShowNotif(true)
        setTimeout(() => {
          setShowNotif(false);
        }, 6000);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const { control, handleSubmit } = useForm();
  const childrenFields = [];

  for (let i = 0; i < numChildren; i++) {
    const childNum = i + 1;
    childrenFields.push(
      <Grid container spacing={2} key={i} marginTop={2}>
        <Grid item xs={12} sm={4}>
          <Controller
            name={`children[${i}].first_name`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <CssTextField
                {...field}
                label={`Child ${childNum} First Name`}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name={`children[${i}].middle_name`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <CssTextField
                {...field}
                label={`Child ${childNum} Middle Name`}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name={`children[${i}].last_name`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <CssTextField
                {...field}
                label={`Child ${childNum} Last Name`}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} marginBottom={2}>
          <Controller
            name={`children[${i}].date_of_birth`}
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={`Child ${childNum} Date of Birth`}
                  inputFormat="MM/DD/YYYY"
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name={`children[${i}].gender`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="gender-select-label">Gender</InputLabel>
                <Select
                  labelId="gender-select-label"
                  id="gender-select"
                  {...field}
                  label={`Child ${childNum} Gender`}
                  required
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="first_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <CssTextField
                {...field}
                required
                label="First Name"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="middle_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <CssTextField
                {...field}
                label="Middle Name"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="last_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <CssTextField
                {...field}
                label="Last Name"
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="gender"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="gender-select-label">Gender</InputLabel>
                <Select
                  labelId="gender-select-label"
                  id="gender-select"
                  {...field}
                  label="Gender"
                  required
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="date_of_birth" // change name to a unique name
            control={control}
            defaultValue=""
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field} // pass the field props to the DatePicker component
                  label="D.O.B"
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="street_name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CssTextField
                  {...field}
                  label="Street Name"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="house_number"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CssTextField
                  {...field}
                  label="House Number"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="zip_code"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CssTextField
                  {...field}
                  label="Zip Code"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="city"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CssTextField
                  {...field}
                  label="City"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="country"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CssTextField
                  {...field}
                  label="Country"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CssTextField
                  {...field}
                  label="Email"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="telephone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CssTextField
                  {...field}
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="marital-status-select-label">Marital Status</InputLabel>
            <Select
              labelId="marital-status-select-label"
              id="marital-status-select"
              value={maritalStatus}
              onChange={(event) => setMaritalStatus(event.target.value)}
              label="Marital Status"
              required
            >
              <MenuItem value="single">Single</MenuItem>
              <MenuItem value="married">Married</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {maritalStatus === "married" && (
          <Box sx={{ mt: 2, ml: 2 }}>

            <Typography variant="h3" margin={4}>
              Partner
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="partner_first_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CssTextField
                      {...field}
                      label="Partner's First Name"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="partner_middle_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CssTextField
                      {...field}
                      label="Partner's Middle Name"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="partner_last_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CssTextField
                      {...field}
                      label="Partner's Last Name"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="partner_date_of_birth"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        {...field}
                        label="Partner's D.O.B"
                      />
                    </LocalizationProvider>
                  )}
                />

              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="partner_email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CssTextField
                      {...field}
                      label="Partner's Email"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="partner_telephone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CssTextField
                      {...field}
                      label="Partner's Phone"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="partner_gender"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="gender-select-label">Gender</InputLabel>
                      <Select
                        labelId="gender-select-label"
                        id="gender-select"
                        {...field}
                        label="Partner's Gender"
                        required
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="numberKids"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CssTextField
                    {...field}
                    label="No. Of Children"
                    type="number"
                    variant="outlined"
                    value={numChildren}
                    onChange={(event) => setNumChildren(event.target.value)}
                    fullWidth
                  />
                )}
              />
            </Grid>
            {numChildren > 0 && (
              <Typography variant="h3" margin={4}>
                Children
              </Typography>
            )}
            {childrenFields}
          </Box>
        )}

      </Grid>
      <Box display="flex" justifyContent="center" p="20px">
        <Button type="submit" variant="contained" sx={{ bgcolor: colors.blueAccent[500], fontWeight: 900 }}>Add Member</Button>
      </Box>
      {showNotif ? (
        <PushNotification message="Member Added" />)
        : (<></>
        )}
    </form>
  );
}
