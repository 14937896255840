import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import Header from "../../components/Header";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { control, handleSubmit } = useForm();
    const [error, setError] = useState()
    const navigate = useNavigate();

    const CssTextField = styled(TextField)({
        '&.MuiTextField-root': {
            margin: "5px"
        },
        '& label.Mui-focused': {
            color: colors.greenAccent[300],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.blueAccent[200],
            },
            '&:hover fieldset': {
                borderColor: colors.greenAccent[500],
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.blueAccent[500],
            },
        },
    });

    const onReset = async (email, current_password, new_password1, new_password2) => {
        if (new_password1 !== new_password2) {
            setError("New Passwords don't match")
        } else {

            // Create the user reset data object
            const userResetData = {
                email,
                password: new_password1,
                old_password: current_password,
            };

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}user/reset`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(userResetData),
                });

                if (response.status === 404) {
                    setError("Failed to reset user. User not found");
                } else if (response.status === 409){
                    setError("Failed to reset user. Please make sure you're entering the correct current password.");
                } else {
                    const data = await response.json();
                    navigate("/");
                    return data;
                }


            } catch (error) {
                console.error(error);
                setError('An error occurred while resetting user.');
            }
        };
    }


    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
        >
            <Box minWidth="25%">
                <Box
                    display="flex"
                    justifyContent="center"
                >
                    <Header title="Reset Password" subtitle="" />
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                >
                    {error ? (
                        <p style={{ color: colors.redAccent[500] }}>{error}</p>
                    ) : (<p></p>)}
                </Box>
                <form onSubmit={handleSubmit((data) => onReset(data.email, data.current_password, data.new_password1, data.new_password2))}>
                    <Grid spacing={2} margin={2}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    required
                                    label="Email"
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid spacing={2} margin={2}>
                        <Controller
                            name="current_password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    required
                                    label="Current Password"
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid spacing={2} margin={2}>
                        <Controller
                            name="new_password1"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    required
                                    label="New Password"
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                />
                            )}
                        />
                        <Controller
                            name="new_password2"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CssTextField
                                    {...field}
                                    required
                                    label="Confirm Password"
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <Button type="submit" variant="contained" margin={2} fullWidth sx={{ bgcolor: colors.blueAccent[500], fontWeight: 900 }}>Reset Password</Button>
                    </Box>

                </form>
            </Box>

        </Box>
    );
}

export default ResetPassword;
