import React, { useEffect, useState, useContext } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { fetchUsersData } from "./users_api_data";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import AccessLevelContext from "../../components/AccessLevelContext";

const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [usersData, setUsersData] = useState([])
  const accessLevel = useContext(AccessLevelContext);
  const columns = [
    {
      field: "username",
      headerName: "Userame",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "access",
      headerName: "Access",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchUsersData();
      console.log(data);
      setUsersData(data);
    };

    fetchData();
  }, []);

  return (
    <Box m="20px">
      <Header title="Users" />
      {accessLevel === "ADMIN" && (
        <Box display="flex" justifyContent="flex-end" p="10px">
          <Button 
            component={Link}
            to="/user/new"
            variant="contained"
            sx={{ bgcolor: colors.blueAccent[500], fontWeight: 900, mr: "20px" }}>
              Add User
          </Button>
        </Box>
      )}
      <Box
        m="40px 0 0 0"
        height="75vh"
      >
        <DataGrid rows={usersData} columns={columns} />
      </Box>
    </Box>
  );
};

export default Users;
