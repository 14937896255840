import React, { useEffect, useState } from 'react'
import { DataGrid } from "@mui/x-data-grid";
import { fetchMemberPayments } from './member_payments_by_id';
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PushNotification from '../../components/PushNotification';


function MemberPaymentsTable({ accessLevel }) {
    const [membersData, setMembersData] = useState([]);
    const { control, handleSubmit } = useForm();
    const params = useParams();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [showNotif, setShowNotif] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchMemberPayments(params.id);
            setMembersData(data);
        };
        fetchData();
    }, [params.id]);

    const CssTextField = styled(TextField)({
        '&.MuiTextField-root': {
            margin: "5px"
        },
        '& label.Mui-focused': {
            color: colors.greenAccent[300],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.blueAccent[200],
            },
            '&:hover fieldset': {
                borderColor: colors.greenAccent[500],
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.blueAccent[500],
            },
        },
    });

    const columns = [
        {
            field: "subscription",
            headerName: "Subscription",
            flex: 1,
            cellClassName: "membership-column--cell",
        },
        {
            field: "membership",
            headerName: "Membership",
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "surcharge",
            headerName: "Surcharge",
            flex: 1,
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
        },
        // {
        //     field: "payment_period",
        //     headerName: "Payment Period",
        //     flex: 1,
        // },
        {
            field: "payment_date",
            headerName: "Payment Date",
            flex: 1,
        }
    ];

    const deleteEmptyStrings = (obj) => {
        for (let key in obj) {
            if (typeof obj[key] === 'string' && obj[key].trim() === '') {
                delete obj[key];
            }
        }
    }

    const onSubmit = async (data) => {
        const url = `${process.env.REACT_APP_API_URL}payments`
        deleteEmptyStrings(data)
        data.membership_id = params.id
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }
        try {
            const response = await fetch(url, options);
            await response.json();
            if (response.ok) {
                setShowNotif(true)
                setTimeout(() => {
                    setShowNotif(false);
                }, 6000);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div>
            {accessLevel === "ADMIN" && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} margin={2}>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="membership"
                                control={control}
                                defaultValue={0.00}
                                render={({ field }) => (
                                    <CssTextField
                                        {...field}
                                        required
                                        label="Membership"
                                        variant="outlined"
                                        type='number'
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="subscription"
                                control={control}
                                defaultValue={0.00}
                                render={({ field }) => (
                                    <CssTextField
                                        {...field}
                                        required
                                        label="Subscription"
                                        variant="outlined"
                                        type='number'
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="surcharge"
                                control={control}
                                defaultValue={0.00}
                                render={({ field }) => (
                                    <CssTextField
                                        {...field}
                                        required
                                        label="Surcharge"
                                        variant="outlined"
                                        type='number'
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="description"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <CssTextField
                                        {...field}
                                        label="Description"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="center" p="20px">
                        <Button type="submit" variant="contained" sx={{ bgcolor: colors.blueAccent[500], fontWeight: 900 }}>Add Payment</Button>
                    </Box>
                </form>
            )}
            <div>
                <Typography variant="h1" margin={4}>
                    Payment History
                </Typography>
            </div>

            {membersData.length === 0 ? (
                <div>No Payments</div>
            ) : (
                <div style={{ height: '400px' }}>
                    <DataGrid
                        key={membersData.length}
                        rows={membersData}
                        columns={columns}
                        getRowId={(row) => row.id}
                    />
                </div>
            )}
            {showNotif ? (
                <PushNotification message="Payment Added" />)
                : (<></>
                )}
        </div>
    )
}

export default MemberPaymentsTable
