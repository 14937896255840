const deleteEmptyStrings = (obj) => {
    for (let key in obj) {
        if (typeof obj[key] === 'string' && obj[key].trim() === '') {
            delete obj[key];
        }
    }
}

export const updateMember = async (membershipId, data) => {
    const token = sessionStorage.getItem("token");
    const url = `${process.env.REACT_APP_API_URL}members/update?membership_id=${membershipId}`
    deleteEmptyStrings(data)
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(data),
    }
    try {
        const response = await fetch(url, options);
        const data = await response.json();
        return data
    } catch (error) {
        console.error(error);
    }
  };
