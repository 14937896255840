export const fetchUsersData = async () => {
    const token = sessionStorage.getItem("token");
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}user`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  };
  